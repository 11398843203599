$show-mobile-menu-width: 800px;

:root {
    --wp-admin-bar-height: 32px;
    --topnav-min-height: 48px;

    @media screen and (max-width: 782px) {
        --wp-admin-bar-height: 46px;
    }

    --step--2: clamp(0.69rem, calc(0.57rem + 0.64vw), 0.99rem);
    --step--1: clamp(0.83rem, calc(0.71rem + 0.61vw), 1.11rem);
    --step-0: clamp(1.00rem, calc(0.89rem + 0.55vw), 1.25rem);
    --step-1: clamp(1.20rem, calc(1.11rem + 0.45vw), 1.41rem);
    --step-2: clamp(1.44rem, calc(1.38rem + 0.31vw), 1.58rem);
    --step-3: clamp(1.73rem, calc(1.71rem + 0.11vw), 1.78rem);
    --step-4: clamp(2.00rem, calc(2.11rem + -0.16vw), 2.07rem);
    --step-5: clamp(2.25rem, calc(2.59rem + -0.52vw), 2.49rem);

    --space-0: 48px;
    @media screen and (max-width: $show-mobile-menu-width) {
        --space-0: 24px;
    }

    --max-width: 1048px;

    --harddark: #1A1A1A;
}
*, *::before, *::after {
    box-sizing: border-box;
}
* {
    margin: 0;
}
html {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    scroll-padding-top: var(--topnav-min-height); 
    &.with-admin-bar {
        scroll-padding-top: calc(var(--topnav-min-height) + var(--wp-admin-bar-height));
    }
}
body {
    -webkit-font-smoothing: antialiased;
    font-family: 'Noto Serif', serif;
    font-size: var(--step-0);
    line-height: 1.5;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}
input, button, textarea, select {
    font: inherit;
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 700;
}
p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}
h1 { font-size: var(--step-5); line-height: 1.1; }
h2 { font-size: var(--step-4); line-height: 1.2; }
h3 { font-size: var(--step-3); line-height: 1.3; }
h4 { font-size: var(--step-2); line-height: 1.4; }
h5 { font-size: var(--step-1); }
h6 { font-size: var(--step-0); }
ol, ul, p, h2, h3, h4, h5, h6 {
    margin: 0.5em 0;
}
ol>li, ul>li {
    margin-left: 1.5em;
    padding-left: 1em;
}
ol li+li, ul li+li {
    margin-top: 0.5em;
}
.overflow-hidden {
    overflow: hidden;
}
header.main {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 max(calc((100% - var(--max-width))/2), 0px);
    padding-bottom: 0.25rem;
    width: 100%;
    border-bottom: 2px solid #000;
    position: fixed;
    top: 0;
    z-index: 10;
    background: #fff;

    nav {
        flex: 0 0 auto;
        margin: 0;
        padding: 0 var(--space-0);
        flex-wrap: wrap;
        white-space: nowrap;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;

        .logo {
            height: 100%;

            img {
                height: 100%;
                width: auto;
            }
        }

        button.open,
        button.close {
            border: none;
            background: transparent;
            cursor: pointer;
            display: none;
            align-items: center;
            justify-content: center;
            padding: 4px;

            img {
                width: 32px;
                height: 32px;
            }
        }

        .menu {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            gap: 1em;
            font-family: Arial;
            font-size: var(--step--1);
            margin-bottom: 5px;

            li {
                margin: 0;
                padding: 0;
            }

            a {
                color: inherit;
                text-decoration: none;
            }
        }

        @media screen and (max-width: $show-mobile-menu-width) {
            padding: 0 0 0 var(--space-0);

            button.open,
            button.close {
                display: flex;
            }

            button.close {
              align-self: flex-end;
              flex-shrink: 0;
            }

            .menu-wrapper {
                position: fixed;
                z-index: 999;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                pointer-events: none;
                background: rgba(0, 0, 0, 0);
                transition: background-color 200ms;
                justify-content: flex-end;

                &.open {
                    pointer-events: auto;
                    opacity: 1;
                    background: rgba(0, 0, 0, 0.6);

                    .inner-menu-wrapper {
                        transform: translateX(0);
                        transition-timing-function: ease-in-out;
                    }
                }
            }

            .inner-menu-wrapper {
                display: flex;
                flex-direction: column;
                max-width: 200px;
                width: 100%;
                transform: translateX(100%);
                transition: transform 150ms ease-in;
                background: var(--harddark);
            }

            .menu {
                flex-grow: 1;
                flex-direction: column;
                margin: 0;
                justify-content: flex-start;
                overflow-y: auto;
                color: #fff;
                gap: 0;

                li {
                  display: block;
                }

                a {
                  display: flex;
                  padding: 5px 15px;
                }
            }
        }
    }
}
main {
    padding: calc(110px + 2em) 0 2em 0;
    @media screen and (max-width: $show-mobile-menu-width) {
        padding-top: calc(var(--topnav-min-height) + 2em);
    }
}
footer.main {
    background: var(--harddark);
    color: #fff;
    font-size: var(--step--2);
    padding: 4em calc(max(calc((100% - var(--max-width))/2), 0px) + var(--space-0));
    padding-bottom: 5em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2em;

    section {
        max-width: 400px;
    }
    h1 {
        font-size: var(--step-0);
        font-weight: 500;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin: 0;
            padding: 0;
        }
    }
}
.container {
    max-width: var(--max-width);
    margin: 0 auto;
    padding-left: var(--space-0);
    padding-right: var(--space-0);
}
.flex-column {
    display: flex;
    flex-direction: column;
}
.text-smaller {
    font-size: 80%;
}
.social-links {
    display: flex;
    gap: 1em;

    li {
        img {
            width: 32px;
        }
    }
}
.books {
    display: flex;
    gap: 1em;
}