:root {
  --wp-admin-bar-height: 32px;
  --topnav-min-height: 48px;
  --step--2: clamp(.69rem, calc(.57rem + .64vw), .99rem);
  --step--1: clamp(.83rem, calc(.71rem + .61vw), 1.11rem);
  --step-0: clamp(1rem, calc(.89rem + .55vw), 1.25rem);
  --step-1: clamp(1.2rem, calc(1.11rem + .45vw), 1.41rem);
  --step-2: clamp(1.44rem, calc(1.38rem + .31vw), 1.58rem);
  --step-3: clamp(1.73rem, calc(1.71rem + .11vw), 1.78rem);
  --step-4: clamp(2rem, calc(2.11rem + -.16vw), 2.07rem);
  --step-5: clamp(2.25rem, calc(2.59rem + -.52vw), 2.49rem);
  --space-0: 48px;
  --max-width: 1048px;
  --harddark: #1a1a1a;
}

@media screen and (max-width: 782px) {
  :root {
    --wp-admin-bar-height: 46px;
  }
}

@media screen and (max-width: 800px) {
  :root {
    --space-0: 24px;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html {
  min-height: 100%;
  scroll-padding-top: var(--topnav-min-height);
  flex-direction: column;
  display: flex;
}

html.with-admin-bar {
  scroll-padding-top: calc(var(--topnav-min-height)  + var(--wp-admin-bar-height));
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: Noto Serif, serif;
  font-size: var(--step-0);
  flex-direction: column;
  flex-grow: 1;
  line-height: 1.5;
  display: flex;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Franklin Gothic Medium, Arial Narrow, Arial, sans-serif;
  font-weight: 700;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

h1 {
  font-size: var(--step-5);
  line-height: 1.1;
}

h2 {
  font-size: var(--step-4);
  line-height: 1.2;
}

h3 {
  font-size: var(--step-3);
  line-height: 1.3;
}

h4 {
  font-size: var(--step-2);
  line-height: 1.4;
}

h5 {
  font-size: var(--step-1);
}

h6 {
  font-size: var(--step-0);
}

ol, ul, p, h2, h3, h4, h5, h6 {
  margin: .5em 0;
}

ol > li, ul > li {
  margin-left: 1.5em;
  padding-left: 1em;
}

ol li + li, ul li + li {
  margin-top: .5em;
}

.overflow-hidden {
  overflow: hidden;
}

header.main {
  padding: 0 max((100% - var(--max-width)) / 2, 0px);
  width: 100%;
  z-index: 10;
  background: #fff;
  border-bottom: 2px solid #000;
  flex: none;
  justify-content: space-between;
  padding-bottom: .25rem;
  display: flex;
  position: fixed;
  top: 0;
}

header.main nav {
  padding: 0 var(--space-0);
  white-space: nowrap;
  width: 100%;
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;
  display: flex;
}

header.main nav .logo {
  height: 100%;
}

header.main nav .logo img {
  height: 100%;
  width: auto;
}

header.main nav button.open, header.main nav button.close {
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: none;
}

header.main nav button.open img, header.main nav button.close img {
  width: 32px;
  height: 32px;
}

header.main nav .menu {
  font-family: Arial;
  font-size: var(--step--1);
  gap: 1em;
  margin: 0 0 5px;
  padding: 0;
  list-style: none;
  display: flex;
}

header.main nav .menu li {
  margin: 0;
  padding: 0;
}

header.main nav .menu a {
  color: inherit;
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  header.main nav {
    padding: 0 0 0 var(--space-0);
  }

  header.main nav button.open, header.main nav button.close {
    display: flex;
  }

  header.main nav button.close {
    flex-shrink: 0;
    align-self: flex-end;
  }

  header.main nav .menu-wrapper {
    z-index: 999;
    pointer-events: none;
    background: none;
    justify-content: flex-end;
    transition: background-color .2s;
    display: flex;
    position: fixed;
    inset: 0;
  }

  header.main nav .menu-wrapper.open {
    pointer-events: auto;
    opacity: 1;
    background: #0009;
  }

  header.main nav .menu-wrapper.open .inner-menu-wrapper {
    transition-timing-function: ease-in-out;
    transform: translateX(0);
  }

  header.main nav .inner-menu-wrapper {
    max-width: 200px;
    width: 100%;
    background: var(--harddark);
    flex-direction: column;
    transition: transform .15s ease-in;
    display: flex;
    transform: translateX(100%);
  }

  header.main nav .menu {
    color: #fff;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    gap: 0;
    margin: 0;
    overflow-y: auto;
  }

  header.main nav .menu li {
    display: block;
  }

  header.main nav .menu a {
    padding: 5px 15px;
    display: flex;
  }
}

main {
  padding: calc(110px + 2em) 0 2em;
}

@media screen and (max-width: 800px) {
  main {
    padding-top: calc(var(--topnav-min-height)  + 2em);
  }
}

footer.main {
  background: var(--harddark);
  color: #fff;
  font-size: var(--step--2);
  padding: 4em calc(max((100% - var(--max-width)) / 2, 0px)  + var(--space-0));
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2em;
  padding-bottom: 5em;
  display: flex;
}

footer.main section {
  max-width: 400px;
}

footer.main h1 {
  font-size: var(--step-0);
  font-weight: 500;
}

footer.main ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

footer.main ul li {
  margin: 0;
  padding: 0;
}

.container {
  max-width: var(--max-width);
  padding-left: var(--space-0);
  padding-right: var(--space-0);
  margin: 0 auto;
}

.flex-column {
  flex-direction: column;
  display: flex;
}

.text-smaller {
  font-size: 80%;
}

.social-links {
  gap: 1em;
  display: flex;
}

.social-links li img {
  width: 32px;
}

.books {
  gap: 1em;
  display: flex;
}

/*# sourceMappingURL=index.e7fd8dfe.css.map */
